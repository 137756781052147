<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template v-slot:header>
          <y_choose_member @change="choose">
            <el-button type="primary">添加核销员</el-button>
          </y_choose_member>
        </template>
        <template v-slot="s">
          <el-button @click="del(s.row)" type="danger">移除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

import y_choose_member from "@/components/shop/y_choose_member";
export default {
  components:{y_choose_member},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editForm:{
        uid:0,
      },
      store_id:0,
    }
  },
  mounted() {
    this.store_id = parseInt(this.$route.query.store_id);
    this.load();
  },
  methods:{
    del({uid}){
      this.$u.api.shop.plugin.scanGoods.clerkV2Set({uid:uid,status:false}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    choose({id}){
      this.$u.api.shop.plugin.scanGoods.clerkV2Set({uid:id,status:true,store_id:this.store_id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
        this.$u.api.shop.plugin.scanGoods.clerkV2All({page:this.page,store_id:this.store_id}).then(res=>{
          this.list = res;
          this.total = res.length;
        })
    },
  },
}
</script>

<style scoped>

</style>